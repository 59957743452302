<template>
    <div>
        <Breadcrumb titulo="SALA-GRUPOS" :items="item" backgroundColor="rgb(82 178 146)" />
        <div class="container-fluid p-3">

            <div class="row m-0">
                <div class="col p-0 d-flex">
                    <botao-aba-sala-grupos v-for="(grupo, index) in listaGrupos"
                                         id="grupo"
                                         :key="index"
                                         @click="selecionaSalaGrupos(grupo.Id)"
                                         @fechar-aba="deseleciona"
                                         :selecionado="(grupoSelecionado && grupoSelecionado.Id == grupo.Id)"
                                         :exibirBotaoFechar="false"
                                         :loading="loading"
                                         v-if="getGruposHabilitados.includes(grupo.CodigoProtocolo.toString())">

                        <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" :title="( grupo.NomeGrupo.length > 11 ? grupo.NomeGrupo : '' )">

                            <p class="textos-grupos">{{ grupo.NomeGrupo.length > 11 ? grupo.NomeGrupo.substring(0, 11)+'...' : grupo.NomeGrupo }}</p>
                        </span>


                    </botao-aba-sala-grupos>
                </div>


            </div>
            <div class="row m-0 position-relative sala-grupos-page-row pt-3" v-if="grupoSelecionado">
                <div class="mx-auto col-9" style="max-height: 100%;">
                    <SalaGrupos class="h-100"
                              :loading="grupoConversasLoading"
                              :conversa="listaConversas"
                              @enviar="enviarMensagem" />
                </div>
                <div class="mx-auto col-3">
                    <InfoSalaGrupos :loading="grupoIntegranteLoading"
                                  :integrantes="listaIntegrantes"/>
                </div>
            </div>
            <div v-else class="row m-0 position-relative pt-2" style="height:calc(100vh - 180px); font-size: 28px;">
                <div style="margin: 0 auto;width: 300px;text-align: center;padding-top: calc(25% - 130px);">
                    SEM SALA DE CHAT SELECIONADO!
                    <i class="fa fa-comments"></i>
                </div>
                <b-overlay :show="(grupoSelecionadoLoading || getLoading)" rounded="sm" no-wrap>
                </b-overlay>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters} from 'vuex'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import BotaoAbaSalaGrupos from '@/components/sala-grupos/botoes/BotaoAbaSalaGrupos.vue'
    import SalaGrupos from '@/components/sala-grupos/SalaGrupos.vue'
    import InfoSalaGrupos from '@/components/sala-grupos/InfoSalaGrupos.vue'

    export default {
        name: 'SalaGruposPage',

        components: {
            Breadcrumb,
            BotaoAbaSalaGrupos,
            SalaGrupos,
            InfoSalaGrupos
        },

        data: function () {
            return {
                loading: false,
                item: [{
                    id: '2',
                    classe: 'fas fa-comments',
                    texto: 'SalaGrupos',
                    link: '/sala-grupos'
                }],
                selecionadoLoading: false,
                GruposHabilitados: []
            }
        },

        computed: {
            ...mapGetters({
                usuarioLogado: 'authentication/authDadosUsuario',
                getUsuarioById: 'usuarios/getUsuarioById',
                getLoading: 'salaGrupos/loading',
                getAtendimentos: 'atendimento/listaAtendimentos',
                listaGrupos: 'salaGrupos/listaGrupos',
                listaConversas: 'salaGrupos/listaConversas',
                listaIntegrantes: 'salaGrupos/listaIntegrantes',
                deselecionaGrupo: 'salaGrupos/deselecionaGrupo',
                grupoSelecionado: 'salaGrupos/grupoSelecionado',
                grupoSelecionadoLoading: 'salaGrupos/grupoSelecionadoLoading',
                grupoConversasLoading: 'salaGrupos/grupoConversasLoading',
                grupoIntegranteLoading: 'salaGrupos/grupoIntegranteLoading'
            }),
            salaChatLoading() {
                return this.getLoading
            },
            getGruposHabilitados() {
                return this.getUsuarioById(this.usuarioLogado.usuarioId).GruposHabilitados?.split(',') ?? []
            }
        },

        created() {
            this.buscaUsuarios()
            this.buscarGrupos()
            this.deseleciona()
        },

        methods: {
            ...mapActions({
                buscarListaUsuarios: 'usuarios/buscaUsuarios',
                buscarGruposStore: 'salaGrupos/buscaGrupos',
                deselecionaGrupoStore: 'salaGrupos/deselecionaGrupo',
                grupoSelecionadoStore: 'salaGrupos/grupoSelecionado',                
            }),
            buscaUsuarios() {
                this.buscarListaUsuarios()
            },
            buscarGrupos() {
                this.buscarGruposStore()
            },
            selecionaSalaGrupos(idGrupo, trocar) {
                this.buscarGrupos()

                if (this.selecionado && idGrupo == this.grupoSelecionado?.id && trocar != 'trocar') {
                    return
                }

                this.selecionadoLoading = true
                this.grupoSelecionadoStore(idGrupo)
                    .then(() => {
                        this.selecionadoLoading = false
                    }
                )

            },
            deseleciona() {
                this.deselecionaGrupoStore()
            },
            enviarMensagem(conteudo) {

            }
        }
    }
</script>

<style scoped>
    .sala-grupos-page-row {
        background-color: var(--cinza-3);
        height: calc(100vh - 178px);
    }
    .textos-grupos {
        margin: 5px 0;
    }
</style>