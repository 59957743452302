<template>
    <div class="chat-sala-container">

        <div class="container-fluid h-100">

            <div class="row sala-grupos-row-chat">
                <div id="chat-atendimento-scroll-container" class="col-12 sala-grupos-scroll-custom" style="background-color: #fff;">

                    <div v-for="(msg, index) in conversaInvertida"
                         :key="index"
                         class="w-100 d-flex py-2"
                         :style="msg.UsuarioId == usuarioLogado.usuarioId ? 'flex-direction: row-reverse;': ''">

                        <div class="chat-sala-rows-conversas">
                            <SalaGruposMensagem 
                            :tipoMensagem="msg.TipoMensagem"
                            :mensagem="msg.Mensagem"
                            :dataHora="msg.Data"
                            :nome="msg.Nome"
                            :ehMinhaMensagem="msg.UsuarioId == usuarioLogado.usuarioId ? true : false"/>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row sala-grupos-row-editor">
                <div class="sala-grupos-col-editor col-12 px-0">
                    <SalaGruposEditor :mensagemPredefinida="mensagemPredefinida"
                                           @enviar="enviarMensagem"
                                           @anexar-click="salvarAnexo" />
                </div>
            </div>
        </div>

        <b-overlay :show="loading" rounded="sm" no-wrap>
        </b-overlay>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SalaGruposEditor from '@/components/sala-grupos/SalaGruposEditor.vue'
import SalaGruposMensagem from '@/components/sala-grupos/SalaGruposMensagem.vue'
import BotaoDropdown from '@/components/botoes/BotaoDropdown.vue'


export default {
    name: 'SalaGrupos',

    components: {
        SalaGruposEditor,
        SalaGruposMensagem,
        BotaoDropdown,
    },

    props: {
        conversa: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
        mensagemPredefinida: {
            type: String,
            default: ''
        },
    },

    data() {
        return {
            indiceAtivo: 0,
            mensagem: ''
        }
    },

    computed: {
        ...mapGetters({
            usuarioLogado: 'authentication/authDadosUsuario',
        }),
        // Inverte pois esta sendo usado flex-direction column-reverse, para manter o scroll no fundo
        conversaInvertida() {
            if(!this.conversa) {
                return
            }
            return this.conversa
                // Faz copia p/ evitar manipular array original
                .slice()
                // Ordena por data decrescente, pois será exibido invertido
                .sort( (msg1, msg2) => {
                    // É necessário inverter de dia/mes/ano para ano/mes/dia
                    // dd/mm/aaaa hh:mm:ss => aaaa/mm/dd hh:mm:ss
                    let dataHora1 =  msg1.Data.split(' ')
                    let data1 = dataHora1[0].split('/').reverse().join('-')
                    let hora1 = dataHora1[1]
                    let newDataHora1 = new Date(`${data1} ${hora1}`)

                    let dataHora2 =  msg2.Data.split(' ')
                    let data2 = dataHora2[0].split('/').reverse().join('-')
                    let hora2 = dataHora2[1]
                    let newDataHora2 = new Date(`${data2} ${hora2}`)

                    return newDataHora2 - newDataHora1
                })
        }
    },

    methods: {
        enviarMensagem(conteudo) {
             this.$emit('enviar', conteudo)
        },
        salvarAnexo(arquivo, publico, mensagem) {
            this.$emit('salvar-anexo', arquivo, publico, mensagem)
        }
    }
}

</script>

<style scoped>
.sala-grupos-container {
    background-color: var(--cinza-3);
    padding: 0px 6px 2px 6px;
    position: relative;
   
}

.sala-grupos-flex-container {
    display: flex;
}

.sala-grupos-row-editor {
    background-color: white;
    padding: 11px;
}
    .sala-grupos-col-editor {
        background-color: #fff;
    }

.sala-grupos-row-chat {
    height: calc(100% - 213px);
    margin-right: -15px;
    margin-left: -15px;
}

.sala-grupos-scroll-custom {
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    scrollbar-width: auto;
    scrollbar-color: #fff lightgrey;
    max-height: 100%;
    background-color: rgb(255, 255, 255);
}
    .sala-grupos-scroll-custom::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 0.3em;
    }
    .sala-grupos-scroll-custom::-webkit-scrollbar-thumb {
        background: lightgrey;
        border-radius: 0.3em;
    }
    .sala-grupos-scroll-custom::-webkit-scrollbar {
        width: .6em;
    }

.sala-grupos-rows-conversas {
    max-width:90%;
}

</style>