<template>
    <div class="dados-sala-grupos h-100">

        <div class="container-fluid h-100">

            <div class="row d-flex flex-column">
                <div class="dados-sala-grupos-flex-row">
                    <div class="dados-sala-grupos-head-tab">
                        Usuarios no Chat
                    </div>
                </div>
            </div>

            <div class="row dados-sala-grupos-row-principal">
                <div class="col-12 dados-sala-grupos-tab app-scroll-custom">
                    <div v-for="(integrante, index) in integrantes"
                         :key="index">
                            {{integrante.Nome}}
                    </div>
                </div>
            </div>

        </div>

        <b-overlay :show="loading" rounded="sm" no-wrap>
        </b-overlay>

    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        name: 'InfoSalaGrupos',

        components: {

        },

        created() {
            console.log(this.integrantes)
        },

        props: {
            loading: {
                type: Boolean,
                default: false
            },
            integrantes: {
                type: Array,
                default: () => []
            }
        },

        data() {
            return {

            }
        },

        computed: {
            ...mapGetters({
                grupoIntegranteLoading: 'salaGrupos/grupoIntegranteLoading'
            })
        }
    }
</script>

<style scoped>
    
    /* CLASSESS>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
    .altura_scroll {
        height: 600px;
    }

    .dados-sala-grupos {
        min-height: 525px;
        position: relative;
    }

    .dados-sala-grupos-flex-row {
        max-width: 100%;
    }

    .dados-sala-grupos-head-tab {
        padding: 3px 9px;
        font-weight: bold;
        font-size: 22px;
        text-align-last: start;
        color: white;
        text-align: center;
        background-color: var(--cor-primaria-cliente);
    }

        .dados-sala-grupos-head-tab.ativo {
            background-color: #fff;
            cursor: unset;
            text-decoration: underline;
        }

        .dados-sala-grupos-head-tab:hover {
            text-decoration: underline;
        }

    .dados-sala-grupos-tab {
        background-color: #fff;
        padding: 14px;
        max-height: 100%;
        height: 100%;
    }

    .dados-sala-grupos-row-principal {
        height: calc(100vh - 250px);
        overflow-y: auto
    }

    .ajuste_historico_at {
        height: 700px;
    }

    #caixa_tabs {
    }
</style>